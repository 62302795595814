import React from 'react';

import Layout from '../components/global/layout';
import SEO from '../components/global/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <div
      style={{
        backgroundColor: 'rgb(27, 30, 60)',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        <h1 style={{ color: '#fff' }}>WHAT HAPPENED!</h1>
        <p style={{ color: '#fff' }}>
          Idk what you are doing here, but go back to where you are supposed to
          be:{' '}
          <a href='/' style={{ color: '#DD645E' }}>
            Go Home
          </a>
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
